import { useSelector } from "../../../../../store";
import { useMemo } from "react";
import {
  useGetAssignmentQuery,
  useGetSchoolInquiryQuery,
} from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { RequestedItemSchoolStatus } from "../../../../../types";
import { selectSelf } from "../../../../../slices/auth/selectors";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";

export const useCanBulkSubmit = (): RequestedItemSchoolStatus | false => {
  const self = useSelector(selectSelf);

  const { inquiryId, departmentInquiryId, assignmentId } =
    useSchoolInquiryItemsTools();

  const { data: inquiry } = useGetSchoolInquiryQuery(inquiryId);

  const { data: assignment } = useGetAssignmentQuery(
    departmentInquiryId && assignmentId
      ? {
          inquiryId,
          departmentInquiryId,
          assignmentId,
        }
      : skipToken
  );

  const preActionCheck = usePreActionCheck();

  return useMemo(() => {
    if (!assignment || !self || !inquiry) {
      return false;
    }

    if (
      assignment?.departmentInquiry?.schoolStatus === "cancelled" ||
      preActionCheck(inquiry.schoolStatus)
    ) {
      return false;
    }

    if (self.role === "teacher") {
      if (
        assignment.schoolStatus !== "new" &&
        assignment.schoolStatus !== "inProgress" &&
        assignment.schoolStatus !== "readyForSending" &&
        assignment.schoolStatus !== "needReviewByTeacher"
      ) {
        return false;
      }

      return "approvedByTeacher";
    }

    if (self.role === "headOfDepartment" && assignment.user.id === self.id) {
      if (
        assignment.schoolStatus !== "readyForSending" &&
        assignment.schoolStatus !== "needReviewByTeacher" &&
        assignment.schoolStatus !== "inProgress"
      ) {
        return false;
      }

      return "approvedByHead";
    }

    if (self.role === "headOfDepartment" && assignment.user.id !== self.id) {
      if (assignment.schoolStatus !== "approvedByTeacher") {
        return false;
      }

      return "approvedByHead";
    }

    return false;
  }, [assignment, inquiry, preActionCheck, self]);
};
