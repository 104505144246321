import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useChangeRequestedItemQuantityMutation } from "../../../../slices/schoolInquiries/api";
import { useMessageHandlers } from "../../../../utils/useMessageHandlers";
import { SimpleNumericControl } from "../../../../components/SimpleNumericControl";
import {
  AssignmentSchoolStatus,
  RequestedItemSchoolStatus,
} from "../../../../types";
import { useSelector } from "../../../../store";
import { selectSelf } from "../../../../slices/auth/selectors";

type RequestedItemQuantityFieldProps = {
  id: number;
  value: number;
  inquiryId: number;
  departmentInquiryId: number;
  assignmentId: number;
  requestedItemSchoolStatus?: RequestedItemSchoolStatus;
  assignmentSchoolStatus?: AssignmentSchoolStatus;
  assignmentUserId?: number;
  loading?: boolean;
  disabled?: boolean;
};

export const RequestedItemQuantityField = ({
  id,
  value,
  inquiryId,
  departmentInquiryId,
  assignmentId,
  requestedItemSchoolStatus,
  assignmentSchoolStatus,
  assignmentUserId,
  loading,
  disabled,
}: RequestedItemQuantityFieldProps) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const self = useSelector(selectSelf);

  const [changeQuantity, { isLoading: isChanging }] =
    useChangeRequestedItemQuantityMutation();

  const [localValue, setLocalValue] = useState(value);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    if (departmentInquiryId && assignmentId && localValue !== value) {
      setPending(true);

      const timeoutId = setTimeout(() => {
        changeQuantity({
          inquiryId,
          departmentInquiryId,
          assignmentId,
          requestedItemId: id,
          quantity: localValue,
        })
          .unwrap()
          .then(handleSuccess)
          .then(() => {
            setPending(false);
          })
          .catch((e) => {
            handleError(e);
            setLocalValue(value);
            setPending(false);
          });
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [
    assignmentId,
    changeQuantity,
    departmentInquiryId,
    handleError,
    handleSuccess,
    id,
    inquiryId,
    localValue,
    value,
  ]);

  const changeHandler = useCallback((value: number) => {
    setLocalValue(value);
  }, []);

  const clickHandler = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.stopPropagation();
    },
    []
  );

  const disabledLocal = useMemo(() => {
    if (self?.role !== "headOfDepartment" && self?.role !== "teacher") {
      return true;
    }

    if (assignmentUserId !== self?.id) {
      return true;
    }

    if (
      assignmentSchoolStatus !== "new" &&
      assignmentSchoolStatus !== "inProgress" &&
      assignmentSchoolStatus !== "readyForSending" &&
      assignmentSchoolStatus !== "needReviewByTeacher"
    ) {
      return true;
    }

    if (self?.role === "headOfDepartment") {
      return (
        requestedItemSchoolStatus !== "new" &&
        requestedItemSchoolStatus !== "approvedByHead" &&
        requestedItemSchoolStatus !== "needReviewByTeacher"
      );
    }

    if (self?.role === "teacher") {
      return (
        requestedItemSchoolStatus !== "new" &&
        requestedItemSchoolStatus !== "approvedByTeacher" &&
        requestedItemSchoolStatus !== "needReviewByTeacher"
      );
    }
  }, [
    assignmentSchoolStatus,
    assignmentUserId,
    requestedItemSchoolStatus,
    self?.id,
    self?.role,
  ]);

  return (
    <SimpleNumericControl
      value={localValue}
      onChange={changeHandler}
      onClick={clickHandler}
      positiveOnly
      disabled={loading || isChanging || disabled || disabledLocal}
      pending={pending || loading || isChanging}
    />
  );
};
