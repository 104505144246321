import React, { useCallback, useMemo } from "react";
import { Button, ButtonGroup, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConfirmationButton } from "../../../../../components/ConfirmationButton";
import { useHistory } from "react-router-dom";
import { useSelector } from "../../../../../store";
import {
  useChangeRequestedItemsStatusMutation,
  useGetAssignmentQuery,
  useGetSchoolInquiryQuery,
} from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useCanBulkSubmit } from "./useCanBulkSubmit";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { Hint } from "../../../../../components/hints/Hint";
import { RouteState } from "../../../../../types";
import AddIcon from "@mui/icons-material/Add";
import { selectSelf } from "../../../../../slices/auth/selectors";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";

type TableControlsProps = {
  selectedIds?: number[];
};

export const TableControls = ({ selectedIds }: TableControlsProps) => {
  const { t } = useTranslation("pages");
  const history = useHistory<RouteState>();
  const { handleSuccess, handleError } = useMessageHandlers();

  const self = useSelector(selectSelf);

  const { baseUrl, inquiryId, departmentInquiryId, assignmentId } =
    useSchoolInquiryItemsTools();

  const { data: inquiry } = useGetSchoolInquiryQuery(inquiryId);

  const { data: assignment } = useGetAssignmentQuery(
    departmentInquiryId && assignmentId
      ? {
          inquiryId,
          departmentInquiryId,
          assignmentId,
        }
      : skipToken
  );

  const addItemHandler = useCallback(() => {
    history.push(`${baseUrl}/add/item`);
  }, [baseUrl, history]);

  const preActionCheck = usePreActionCheck();

  const goToEShopDisabled = useMemo(() => {
    if (!self || !assignment || !inquiry) {
      return true;
    }

    if (
      preActionCheck(
        inquiry.schoolStatus,
        assignment.departmentInquiry.schoolStatus
      )
    ) {
      return true;
    }

    if (self.role !== "headOfDepartment" && self.role !== "teacher") {
      return true;
    }

    if (assignment.user.id !== self.id) {
      return true;
    }

    return (
      assignment.schoolStatus !== "new" &&
      assignment.schoolStatus !== "inProgress" &&
      assignment.schoolStatus !== "readyForSending" &&
      assignment.schoolStatus !== "needReviewByTeacher"
    );
  }, [assignment, inquiry, preActionCheck, self]);

  const [changeStatus, { isLoading: isChanging }] =
    useChangeRequestedItemsStatusMutation();

  const bulkApproveStatus = useCanBulkSubmit();

  const bulkApproveHandler = useCallback(() => {
    if (
      bulkApproveStatus &&
      departmentInquiryId &&
      assignmentId &&
      selectedIds &&
      selectedIds.length > 0
    ) {
      changeStatus({
        inquiryId,
        departmentInquiryId,
        assignmentId,
        itemsIds: selectedIds,
        schoolStatus: bulkApproveStatus,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    assignmentId,
    bulkApproveStatus,
    changeStatus,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
    selectedIds,
  ]);

  const buttons = (() => {
    if (
      selectedIds &&
      selectedIds.length > 0 &&
      (self?.role === "teacher" || self?.role === "headOfDepartment")
    ) {
      return (
        <Hint
          helpText={t("hints.schoolInquiryDetails.approveTableItems")}
          hide={
            !assignment ||
            !assignment.departmentInquiry ||
            assignment.departmentInquiry.schoolStatus === "cancelled" ||
            assignment.departmentInquiry.schoolStatus === "approvedByPrincipal"
          }
        >
          <ConfirmationButton
            component={ToolButton}
            onClick={bulkApproveHandler}
            confirmationTitle={t(
              "schoolInquiryDetails.itemsTableActions.approve.confirmationTitle"
            )}
            confirmationDescription={t("noUndo", { ns: "common" })}
            loading={isChanging}
            disabled={!bulkApproveStatus}
          >
            {t("schoolInquiryDetails.itemsTableActions.approve.label")}
          </ConfirmationButton>
        </Hint>
      );
    }

    return (
      <>
        <Hint
          helpText={t("hints.schoolInquiryDetails.addProduct")}
          hide={goToEShopDisabled}
        >
          <ToolButton
            onClick={addItemHandler}
            variant="outlined"
            disabled={goToEShopDisabled}
            size="small"
          >
            <AddIcon
              sx={{
                fontSize: "18px",
                marginRight: "10px",
              }}
            />
            {t("schoolInquiryDetails.itemsTableActions.addItem")}
          </ToolButton>
        </Hint>
      </>
    );
  })();

  return (
    <ButtonGroup
      size="small"
      sx={{
        marginTop: "16px",
        marginBottom: "16px",
      }}
    >
      {buttons}
    </ButtonGroup>
  );
};

const ToolButton = styled(Button)({
  fontSize: "13px",
  lineHeight: "24px",
  textTransform: "capitalize",
});
