import { InquiryDetailsFlattened } from "../../../../slices/inquiries/types";

export const useCommercialOfferDetailsCheck = ({
  inquiryDetails,
}: {
  inquiryDetails: InquiryDetailsFlattened;
}) => {
  return (
    ((!!inquiryDetails?.shippingCost &&
      !!inquiryDetails?.shippingCostCurrency) ||
      !!inquiryDetails?.shippingCostMessage) &&
    !!inquiryDetails?.paymentTerms &&
    !!inquiryDetails?.deliveryTerms &&
    !!inquiryDetails?.leadTime &&
    !!inquiryDetails?.validityOffer
  );
};
