import { unionItemsProductsInquiriesApi } from "../unionItemsProductsInquiriesApi";
import {
  AddAssignmentRequestDto,
  AddAssignmentResponseDto,
  AddItemToAssignmentRequestDto,
  AddItemToAssignmentResponseDto,
  AddSchoolDepartmentInquiryRequestDto,
  AddSchoolDepartmentInquiryResponseDto,
  AddSchoolInquiryRequestDto,
  AddSchoolInquiryResponseDto,
  ApproveAssignmentRequestDto,
  ApproveAssignmentResponseDto,
  ApproveSchoolDepartmentInquiryRequestDto,
  ApproveSchoolDepartmentInquiryResponseDto,
  CancelAssignmentRequestDto,
  CancelAssignmentResponseDto,
  CancelSchoolDepartmentInquiryRequestDto,
  CancelSchoolDepartmentInquiryResponseDto,
  CancelSchoolInquiryRequestDto,
  CancelSchoolInquiryResponseDto,
  ChangeItemsStatusRequestDto,
  ChangeItemsStatusResponseDto,
  ChangeRequestedItemQuantityRequestDto,
  ChangeRequestedItemQuantityResponseDto,
  ChangeRequestedItemsStatusRequestDto,
  ChangeRequestedItemsStatusResponseDto,
  DeleteAssignmentRequestDto,
  DeleteAssignmentResponseDto,
  DeleteRequestedItemRequestDto,
  DeleteRequestedItemResponseDto,
  DeleteSchoolDepartmentInquiryRequestDto,
  DeleteSchoolDepartmentInquiryResponseDto,
  DeleteSchoolInquiryRequestDto,
  DeleteSchoolInquiryResponseDto,
  EditAssignmentRequestDto,
  EditAssignmentResponseDto,
  EditSchoolDepartmentInquiryRequestDto,
  EditSchoolDepartmentInquiryResponseDto,
  EditSchoolInquiryRequestDto,
  EditSchoolInquiryResponseDto,
  GetAssignmentRequestDto,
  GetAssignmentResponseDto,
  GetSchoolAssignmentRequestDto,
  GetSchoolAssignmentRequestedItemsRequestDto,
  GetSchoolAssignmentRequestedItemsResponseDto,
  GetSchoolAssignmentResponseDto,
  GetSchoolDepartmentInquiryForHeadRequestDto,
  GetSchoolDepartmentInquiryForHeadResponseDto,
  GetSchoolDepartmentInquiryForPrincipalRequestDto,
  GetSchoolDepartmentInquiryForPrincipalResponseDto,
  GetSchoolDepartmentInquiryItemsRequestDto,
  GetSchoolDepartmentInquiryItemsResponseDto,
  GetSchoolDepartmentInquiryRequestDto,
  GetSchoolDepartmentInquiryResponseDto,
  GetSchoolInquiriesCommonRequestDto,
  GetSchoolInquiriesForHeadsResponseDto,
  GetSchoolInquiriesForPrincipalResponseDto,
  GetSchoolInquiriesForTeachersResponseDto,
  GetSchoolInquiryItemRequestDto,
  GetSchoolInquiryItemResponseDto,
  GetSchoolInquiryResponseDto,
  SendAssignmentToHeadRequestDto,
  SendAssignmentToHeadResponseDto,
  SendAssignmentToReviewRequestDto,
  SendAssignmentToReviewResponseDto,
  SendSchoolDepartmentInquiryToPrincipalRequestDto,
  SendSchoolDepartmentInquiryToPrincipalResponseDto,
  SendSchoolDepartmentInquiryToReviewRequestDto,
  SendSchoolDepartmentInquiryToReviewResponseDto,
  SubmitSchoolInquiryRequestDto,
  SubmitSchoolInquiryResponseDto,
} from "./types";

const baseUrl = "school-inquiries";

export const schoolInquiriesApi =
  unionItemsProductsInquiriesApi.injectEndpoints({
    endpoints: (builder) => ({
      getSchoolInquiriesForPrincipals: builder.query<
        GetSchoolInquiriesForPrincipalResponseDto,
        GetSchoolInquiriesCommonRequestDto
      >({
        query: ({ page, pageSize, filter, userId }) => {
          const params = new URLSearchParams({
            page: JSON.stringify(page),
            pageSize: JSON.stringify(pageSize),
          });

          if (filter) {
            params.append("filter", filter);
          }

          if (userId) {
            params.append("userId", userId.toString());
          }

          return `${baseUrl}?${params.toString()}`;
        },
        providesTags: (result) =>
          result
            ? [
                ...result.data.map(({ id }) => ({
                  type: "Inquiry" as const,
                  id,
                })),
                { type: "Inquiry", id: "list" },
              ]
            : [{ type: "Inquiry", id: "list" }],
      }),
      getSchoolInquiriesForHeads: builder.query<
        GetSchoolInquiriesForHeadsResponseDto,
        GetSchoolInquiriesCommonRequestDto
      >({
        query: ({ page, pageSize, filter, userId }) => {
          const params = new URLSearchParams({
            page: JSON.stringify(page),
            pageSize: JSON.stringify(pageSize),
          });

          if (filter) {
            params.append("filter", filter);
          }

          if (userId) {
            params.append("userId", userId.toString());
          }

          return `${baseUrl}?${params.toString()}`;
        },
        providesTags: (result) =>
          result
            ? [
                ...result.data.map(({ id }) => ({
                  type: "Inquiry" as const,
                  id,
                })),
                { type: "Inquiry", id: "list" },
              ]
            : [{ type: "Inquiry", id: "list" }],
      }),
      getSchoolInquiriesForTeachers: builder.query<
        GetSchoolInquiriesForTeachersResponseDto,
        GetSchoolInquiriesCommonRequestDto
      >({
        query: ({ page, pageSize, filter, userId }) => {
          const params = new URLSearchParams({
            page: JSON.stringify(page),
            pageSize: JSON.stringify(pageSize),
          });

          if (filter) {
            params.append("filter", filter);
          }

          if (userId) {
            params.append("userId", userId.toString());
          }

          return `${baseUrl}?${params.toString()}`;
        },
        providesTags: (result) =>
          result
            ? [
                ...result.data.map(({ id }) => ({
                  type: "Inquiry" as const,
                  id,
                })),
                { type: "Inquiry", id: "list" },
              ]
            : [{ type: "Inquiry", id: "list" }],
      }),
      getSchoolInquiry: builder.query<GetSchoolInquiryResponseDto, number>({
        query: (id) => ({
          url: `${baseUrl}/${id}`,
          method: "GET",
        }),
        providesTags: (_result, _error, id) => [{ type: "Inquiry", id }],
      }),
      addSchoolInquiry: builder.mutation<
        AddSchoolInquiryResponseDto,
        AddSchoolInquiryRequestDto
      >({
        query: ({ budget, ...body }) => ({
          url: baseUrl,
          method: "POST",
          body: {
            ...body,
            budget:
              body.isBudget && budget ? Math.trunc(Number(budget) * 100) : null,
          },
        }),
        invalidatesTags: [{ type: "Inquiry", id: "list" }],
      }),
      editSchoolInquiry: builder.mutation<
        EditSchoolInquiryResponseDto,
        EditSchoolInquiryRequestDto
      >({
        query: ({ id, budget, ...body }) => ({
          url: `${baseUrl}/${id}`,
          method: "PUT",
          body: {
            ...body,
            budget:
              body.isBudget && budget ? Math.trunc(Number(budget) * 100) : null,
          },
        }),
        invalidatesTags: (_result, _error, { id }) => [
          { type: "Inquiry", id: "list" },
          { type: "Inquiry", id },
          { type: "DetailedInquiry", id: "list" },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      deleteSchoolInquiry: builder.mutation<
        DeleteSchoolInquiryResponseDto,
        DeleteSchoolInquiryRequestDto
      >({
        query: (id) => ({
          url: `${baseUrl}/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (_result, _error, id) => [
          { type: "Inquiry", id: "list" },
          { type: "Inquiry", id },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      submitSchoolInquiry: builder.mutation<
        SubmitSchoolInquiryResponseDto,
        SubmitSchoolInquiryRequestDto
      >({
        query: ({ id, ...body }) => ({
          url: `${baseUrl}/${id}/submit`,
          method: "PATCH",
          body: {
            ...body,
            note: body.note === "<p></p>" ? null : body.note,
          },
        }),
        invalidatesTags: (_result, _error, { id, departmentInquiriesIds }) => [
          { type: "Inquiry", id: "list" },
          { type: "Inquiry", id },
          ...departmentInquiriesIds.map(
            (departmentInquiryId) =>
              ({
                type: "DetailedInquiry" as const,
                id: departmentInquiryId,
              } as const)
          ),
          { type: "CartAssignments", id: "list" },
        ],
      }),
      cancelSchoolInquiry: builder.mutation<
        CancelSchoolInquiryResponseDto,
        CancelSchoolInquiryRequestDto
      >({
        query: (id) => ({
          url: `${baseUrl}/${id}/cancel`,
          method: "PATCH",
        }),
        invalidatesTags: (_result, _error, id) => [
          { type: "Inquiry", id: "list" },
          { type: "Inquiry", id },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      getSchoolDepartmentInquiry: builder.query<
        GetSchoolDepartmentInquiryResponseDto,
        GetSchoolDepartmentInquiryRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId }) =>
          `${baseUrl}/${inquiryId}/departments/deprecated-${departmentInquiryId}`,
        providesTags: (_result, _error, { departmentInquiryId }) => [
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "DetailedInquiry", id: "list" },
        ],
      }),
      getSchoolDepartmentInquiryForPrincipal: builder.query<
        GetSchoolDepartmentInquiryForPrincipalResponseDto,
        GetSchoolDepartmentInquiryForPrincipalRequestDto
      >({
        query: ({ departmentInquiryId }) =>
          `${baseUrl}/department-inquiries/${departmentInquiryId}`,
        providesTags: (_result, _error, { departmentInquiryId }) => [
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "DetailedInquiry", id: "list" },
        ],
      }),
      getSchoolDepartmentInquiryForHead: builder.query<
        GetSchoolDepartmentInquiryForHeadResponseDto,
        GetSchoolDepartmentInquiryForHeadRequestDto
      >({
        query: ({ departmentInquiryId }) =>
          `${baseUrl}/department-inquiries/${departmentInquiryId}`,
        providesTags: (departmentInquiry, _error, { departmentInquiryId }) => [
          { type: "DetailedInquiry" as const, id: departmentInquiryId },
          ...(departmentInquiry
            ? departmentInquiry.assignments.map((assignment) => ({
                type: "Assignment" as const,
                id: assignment.id,
              }))
            : []),
        ],
      }),
      getSchoolDepartmentInquiryItems: builder.query<
        GetSchoolDepartmentInquiryItemsResponseDto,
        GetSchoolDepartmentInquiryItemsRequestDto
      >({
        query: ({ departmentInquiryId }) =>
          `${baseUrl}/department-inquiries/${departmentInquiryId}/items`,
        providesTags: (_result, _error, { departmentInquiryId }) => [
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "DetailedInquiry", id: "list" },
        ],
      }),
      addSchoolDepartmentInquiry: builder.mutation<
        AddSchoolDepartmentInquiryResponseDto,
        AddSchoolDepartmentInquiryRequestDto
      >({
        query: ({ inquiryId, budget, ...body }) => ({
          url: `${baseUrl}/${inquiryId}/departments`,
          method: "POST",
          body: {
            budget: budget ? Math.trunc(Number(budget) * 100) : null,
            ...body,
          },
        }),
        invalidatesTags: (_result, _error, { inquiryId }) => [
          { type: "Inquiry", id: "list" },
          { type: "Inquiry", inquiryId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      editSchoolDepartmentInquiry: builder.mutation<
        EditSchoolDepartmentInquiryResponseDto,
        EditSchoolDepartmentInquiryRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId, budget, ...body }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}`,
          method: "PUT",
          body: {
            budget: budget ? Math.trunc(Number(budget) * 100) : null,
            ...body,
          },
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId }
        ) => [
          { type: "Inquiry", id: "list" },
          { type: "Inquiry", inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      deleteSchoolDepartmentInquiry: builder.mutation<
        DeleteSchoolDepartmentInquiryResponseDto,
        DeleteSchoolDepartmentInquiryRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}`,
          method: "DELETE",
        }),
        invalidatesTags: (_result, _error, { inquiryId }) => [
          { type: "Inquiry", id: "list" },
          { type: "Inquiry", inquiryId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      sendSchoolDepartmentInquiryToReview: builder.mutation<
        SendSchoolDepartmentInquiryToReviewResponseDto,
        SendSchoolDepartmentInquiryToReviewRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/sendtoreview`,
          method: "PATCH",
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId }
        ) => [
          { type: "Inquiry", id: inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      approveSchoolDepartmentInquiry: builder.mutation<
        ApproveSchoolDepartmentInquiryResponseDto,
        ApproveSchoolDepartmentInquiryRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/approve`,
          method: "PATCH",
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId }
        ) => [
          { type: "Inquiry", id: inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      sendSchoolDepartmentInquiryToPrincipal: builder.mutation<
        SendSchoolDepartmentInquiryToPrincipalResponseDto,
        SendSchoolDepartmentInquiryToPrincipalRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/sendtoprincipal`,
          method: "PATCH",
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId }
        ) => [
          { type: "Inquiry", inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      cancelSchoolDepartmentInquiry: builder.mutation<
        CancelSchoolDepartmentInquiryResponseDto,
        CancelSchoolDepartmentInquiryRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/cancel`,
          method: "PATCH",
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId }
        ) => [
          { type: "Inquiry", id: inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      changeItemsStatus: builder.mutation<
        ChangeItemsStatusResponseDto,
        ChangeItemsStatusRequestDto
      >({
        query: ({
          inquiryId,
          departmentInquiryId,
          itemsIds,
          schoolStatus,
        }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/changestatus`,
          method: "PATCH",
          body: {
            itemsIds,
            schoolStatus,
          },
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId, assignmentsIds }
        ) => [
          { type: "Inquiry", id: inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          ...assignmentsIds.map((assignmentId) => ({
            type: "Assignment" as const,
            id: assignmentId,
          })),
          { type: "CartAssignments", id: "list" },
        ],
      }),
      getSchoolInquiryItem: builder.query<
        GetSchoolInquiryItemResponseDto,
        GetSchoolInquiryItemRequestDto
      >({
        query: ({ inquiryId, itemId }) =>
          `${baseUrl}/${inquiryId}/items/${itemId}`,
      }),
      getSchoolAssignment: builder.query<
        GetSchoolAssignmentResponseDto,
        GetSchoolAssignmentRequestDto
      >({
        query: ({ assignmentId }) => `/${baseUrl}/assignments/${assignmentId}`,
        providesTags: (_result, _error, { assignmentId }) => [
          { type: "Assignment", id: assignmentId },
          { type: "DetailedInquiry", id: "list" },
        ],
      }),
      getSchoolAssignmentRequestedItems: builder.query<
        GetSchoolAssignmentRequestedItemsResponseDto,
        GetSchoolAssignmentRequestedItemsRequestDto
      >({
        query: ({ assignmentId }) =>
          `${baseUrl}/assignments/${assignmentId}/requested-items`,
        providesTags: (_result, _error, { assignmentId }) => [
          { type: "Assignment", id: assignmentId },
          { type: "DetailedInquiry", id: "list" },
        ],
      }),
      getAssignment: builder.query<
        GetAssignmentResponseDto,
        GetAssignmentRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId, assignmentId }) =>
          `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/assignments/deprecated-${assignmentId}`,
        providesTags: (_result, _error, { assignmentId }) => [
          { type: "Assignment", id: assignmentId },
          { type: "DetailedInquiry", id: "list" },
        ],
      }),
      addAssignment: builder.mutation<
        AddAssignmentResponseDto,
        AddAssignmentRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId, budget, ...body }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/assignments`,
          method: "POST",
          body: {
            budget: budget ? Math.trunc(Number(budget) * 100) : null,
            ...body,
          },
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId }
        ) => [
          { type: "Inquiry", id: inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      editAssignment: builder.mutation<
        EditAssignmentResponseDto,
        EditAssignmentRequestDto
      >({
        query: ({
          inquiryId,
          departmentInquiryId,
          assignmentId,
          budget,
          ...body
        }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/assignments/${assignmentId}`,
          method: "PUT",
          body: {
            budget: budget ? Math.trunc(Number(budget) * 100) : null,
            ...body,
          },
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId, assignmentId }
        ) => [
          { type: "Inquiry", id: inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "Assignment", id: assignmentId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      deleteAssignment: builder.mutation<
        DeleteAssignmentResponseDto,
        DeleteAssignmentRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId, assignmentId }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/assignments/${assignmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId }
        ) => [
          { type: "Inquiry", id: inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      deleteRequestedItem: builder.mutation<
        DeleteRequestedItemResponseDto,
        DeleteRequestedItemRequestDto
      >({
        query: ({
          inquiryId,
          departmentInquiryId,
          assignmentId,
          requestedItemId,
        }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/assignments/${assignmentId}/${requestedItemId}`,
          method: "DELETE",
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId, assignmentId }
        ) => [
          { type: "Inquiry", id: inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "Assignment", id: assignmentId },
          { type: "CartAssignments", id: "list" },
          { type: "RequestedItem", id: "list" },
        ],
      }),
      changeRequestedItemsStatus: builder.mutation<
        ChangeRequestedItemsStatusResponseDto,
        ChangeRequestedItemsStatusRequestDto
      >({
        query: ({
          inquiryId,
          departmentInquiryId,
          assignmentId,
          itemsIds,
          schoolStatus,
        }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/assignments/${assignmentId}/changestatus`,
          method: "PATCH",
          body: {
            itemsIds,
            schoolStatus,
          },
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId, assignmentId }
        ) => [
          { type: "Inquiry", id: inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "Assignment", id: assignmentId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      changeRequestedItemQuantity: builder.mutation<
        ChangeRequestedItemQuantityResponseDto,
        ChangeRequestedItemQuantityRequestDto
      >({
        query: ({
          inquiryId,
          departmentInquiryId,
          assignmentId,
          requestedItemId,
          quantity,
        }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/assignments/${assignmentId}/changequantity/${requestedItemId}`,
          method: "PATCH",
          body: { quantity },
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId, assignmentId }
        ) => [
          { type: "Inquiry", id: inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "Assignment", id: assignmentId },
          { type: "RequestedItem", id: "list" },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      sendAssignmentToHead: builder.mutation<
        SendAssignmentToHeadResponseDto,
        SendAssignmentToHeadRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId, assignmentId }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/assignments/${assignmentId}/sendtohead`,
          method: "PATCH",
        }),
        invalidatesTags: (_result, _error, { inquiryId, assignmentId }) => [
          { type: "Inquiry", id: inquiryId },
          { type: "Assignment", id: assignmentId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      approveAssignment: builder.mutation<
        ApproveAssignmentResponseDto,
        ApproveAssignmentRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId, assignmentId }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/assignments/${assignmentId}/approve`,
          method: "PATCH",
        }),
        invalidatesTags: (_result, _error, { inquiryId, assignmentId }) => [
          { type: "Inquiry", id: inquiryId },
          { type: "CartAssignments", id: "list" },
          { type: "Assignment", id: assignmentId },
        ],
      }),
      sendAssignmentToReview: builder.mutation<
        SendAssignmentToReviewResponseDto,
        SendAssignmentToReviewRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId, assignmentId }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/assignments/${assignmentId}/sendtoreview`,
          method: "PATCH",
        }),
        invalidatesTags: (_result, _error, { inquiryId, assignmentId }) => [
          { type: "Inquiry", id: inquiryId },
          { type: "Assignment", id: assignmentId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      cancelAssignment: builder.mutation<
        CancelAssignmentResponseDto,
        CancelAssignmentRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId, assignmentId }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/assignments/${assignmentId}/cancel`,
          method: "PATCH",
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId, assignmentId }
        ) => [
          { type: "Inquiry", id: inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "Assignment", id: assignmentId },
          { type: "CartAssignments", id: "list" },
        ],
      }),
      addItemToAssignment: builder.mutation<
        AddItemToAssignmentResponseDto,
        AddItemToAssignmentRequestDto
      >({
        query: ({ inquiryId, departmentInquiryId, assignmentId, ...body }) => ({
          url: `${baseUrl}/${inquiryId}/departments/${departmentInquiryId}/assignments/${assignmentId}/items`,
          method: "POST",
          body,
        }),
        invalidatesTags: (
          _result,
          _error,
          { inquiryId, departmentInquiryId, assignmentId }
        ) => [
          { type: "Inquiry", id: inquiryId },
          { type: "DetailedInquiry", id: departmentInquiryId },
          { type: "Assignment", id: assignmentId },
          { type: "RequestedItem", id: "list" },
          { type: "CartAssignments", id: "list" },
        ],
      }),
    }),
  });

export const {
  useGetSchoolInquiriesForPrincipalsQuery,
  useGetSchoolInquiriesForHeadsQuery,
  useGetSchoolInquiriesForTeachersQuery,
  useGetSchoolInquiryQuery,
  useAddSchoolInquiryMutation,
  useEditSchoolInquiryMutation,
  useDeleteSchoolInquiryMutation,
  useSubmitSchoolInquiryMutation,
  useCancelSchoolInquiryMutation,
  useGetSchoolDepartmentInquiryQuery,
  useGetSchoolDepartmentInquiryForPrincipalQuery,
  useGetSchoolDepartmentInquiryForHeadQuery,
  useGetSchoolDepartmentInquiryItemsQuery,
  useAddSchoolDepartmentInquiryMutation,
  useEditSchoolDepartmentInquiryMutation,
  useDeleteSchoolDepartmentInquiryMutation,
  useSendSchoolDepartmentInquiryToReviewMutation,
  useApproveSchoolDepartmentInquiryMutation,
  useSendSchoolDepartmentInquiryToPrincipalMutation,
  useCancelSchoolDepartmentInquiryMutation,
  useChangeItemsStatusMutation,
  useGetSchoolInquiryItemQuery,
  useGetSchoolAssignmentRequestedItemsQuery,
  useGetAssignmentQuery,
  useGetSchoolAssignmentQuery,
  useAddAssignmentMutation,
  useEditAssignmentMutation,
  useDeleteAssignmentMutation,
  useDeleteRequestedItemMutation,
  useChangeRequestedItemsStatusMutation,
  useChangeRequestedItemQuantityMutation,
  useSendAssignmentToHeadMutation,
  useApproveAssignmentMutation,
  useSendAssignmentToReviewMutation,
  useCancelAssignmentMutation,
  useAddItemToAssignmentMutation,
} = schoolInquiriesApi;
