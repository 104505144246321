import React from "react";
import { Box, ButtonBase, useTheme } from "@mui/material";
import {
  AssignmentSchoolStatus,
  DepartmentInquirySchoolStatus,
} from "../../../../../../../types";
import { Name } from "./Name";
import {
  Budget,
  BudgetProps,
} from "../../../../../../../components/Budget/Budget";
import { Status } from "./Status";
import { colors } from "../../../../../../../theme/astra/colors";
import { Link } from "react-router-dom";
import { Role } from "../../../../../../../permissions/permissions";

type ListProps =
  | {
      type: "departmentInquiry";
      status: DepartmentInquirySchoolStatus;
      budget: BudgetProps;
      focused: boolean;
      to: string;
      actions: React.ReactNode;
    }
  | {
      type: "assignment";
      status: AssignmentSchoolStatus;
      user: string;
      role: Role;
      budget: BudgetProps;
      focused: boolean;
      to: string;
      actions: React.ReactNode;
    };

export const List = (props: ListProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <ButtonBase
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          display: "flex",
          justifyContent: "flex-start",
          borderRadius: "4px",
          padding: "7px 24px",
          marginLeft: "-24px",
          transition: theme.transitions.create(["background-color"], {
            duration: theme.transitions.duration.leavingScreen,
          }),
          boxSizing: "border-box",
          border: props.focused
            ? `1px solid ${colors.dustBlue5}`
            : "1px solid transparent",

          "&:hover": {
            backgroundColor: colors.dustBlue6,
            transition: theme.transitions.create(["background-color"], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          },

          ".MuiTouchRipple-rippleVisible": {
            color: colors.dustBlue1,
          },
        }}
        component={Link}
        to={props.to}
      >
        {props.type === "departmentInquiry" ? (
          <Name type="departmentInquiry" />
        ) : (
          <Name type="assignment" user={props.user} role={props.role} />
        )}
        {props.type === "departmentInquiry" ? (
          <Status type="departmentInquiry" status={props.status} />
        ) : (
          <Status
            type="assignment"
            status={props.status}
            user={props.user}
            role={props.role}
          />
        )}
        <Budget {...props.budget} />
      </ButtonBase>
      <Box
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          display: "flex",
          gap: "20px",
        }}
      >
        {props.actions}
      </Box>
    </Box>
  );
};
