import React, { useCallback } from "react";
import { LoadingTextField } from "../LoadingTextField";
import { MenuItem, TextFieldProps } from "@mui/material";
import { useGetAstraEntitiesAsOptionsQuery } from "../../slices/astraEntities/api";
import { useTranslation } from "react-i18next";

export type SelectAstraEntityProps = Omit<TextFieldProps, "select"> & {
  nullable?: boolean;
};

export const SelectAstraEntity = ({
  value = "",
  nullable = false,
  onChange,
  ...props
}: SelectAstraEntityProps) => {
  const { t } = useTranslation("common");

  const { data: astraEntities = [], isLoading: areAstraEntitiesLoading } =
    useGetAstraEntitiesAsOptionsQuery();

  const changeHandler = useCallback<NonNullable<TextFieldProps["onChange"]>>(
    (event) => {
      if (onChange) {
        onChange(event);
      }
    },
    [onChange]
  );

  const noOptions =
    !nullable && astraEntities.length < 1 ? (
      <MenuItem key={"empty-list"} disabled>
        {t("emptySelect", { ns: "common" })}
      </MenuItem>
    ) : null;

  const dash = nullable ? (
    <MenuItem key="" value="">
      {t("none", { ns: "common" })}
    </MenuItem>
  ) : null;

  const options = astraEntities.map(({ id, name }) => (
    <MenuItem key={id} value={id}>
      {name}
    </MenuItem>
  ));

  return (
    <LoadingTextField
      select
      loading={areAstraEntitiesLoading}
      value={value}
      onChange={changeHandler}
      {...props}
    >
      {noOptions}
      {dash}
      {options}
    </LoadingTextField>
  );
};
