import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import {
  AssignmentSchoolStatus,
  DepartmentInquirySchoolStatus,
  InquirySchoolStatus,
  RequestedItemSchoolStatus,
  requestedItemSchoolStatuses,
} from "../../../../types";
import { useSelector } from "../../../../store";
import { requestedItemSchoolStatusesPaths } from "../../../../slices/schoolInquiries/utils";
import { useChangeRequestedItemsStatusMutation } from "../../../../slices/schoolInquiries/api";
import { useMessageHandlers } from "../../../../utils/useMessageHandlers";
import { useTranslation } from "react-i18next";
import { REQUEST_ITEM_SCHOOL_STATUS_CHIP_STYLES } from "../../../../consts";
import { selectSelf } from "../../../../slices/auth/selectors";
import { SchoolStatusChip } from "../../../../components/SchoolStatusChip";
import { usePreActionCheck } from "../../../../slices/schoolInquiries/hooks";

type SelectRequestedItemStatusProps = {
  inquiryId: number;
  inquirySchoolStatus?: InquirySchoolStatus;
  departmentInquiryId: number;
  departmentInquirySchoolStatus: DepartmentInquirySchoolStatus;
  assignmentId: number;
  assignmentSchoolStatus: AssignmentSchoolStatus;
  assignmentUserId: number;
  requestedItemId: number;
  requestedItemSchoolStatus: RequestedItemSchoolStatus;
  isBulkSelected?: boolean;
};

export const SelectRequestedItemStatus = ({
  inquiryId,
  inquirySchoolStatus,
  requestedItemId,
  requestedItemSchoolStatus,
  departmentInquiryId,
  departmentInquirySchoolStatus,
  assignmentId,
  assignmentSchoolStatus,
  assignmentUserId,
  isBulkSelected,
}: SelectRequestedItemStatusProps) => {
  const { t } = useTranslation("common");
  const { handleSuccess, handleError } = useMessageHandlers();

  const self = useSelector(selectSelf);

  const [changeRequestedItemsStatus, { isLoading: isChanging }] =
    useChangeRequestedItemsStatusMutation();

  const [localValue, setLocalValue] = useState(
    requestedItemSchoolStatus || "new"
  );

  const changeHandler = useCallback((event) => {
    setLocalValue(event.target.value);
  }, []);

  useEffect(() => {
    if (requestedItemSchoolStatus) {
      setLocalValue(requestedItemSchoolStatus);
    }
  }, [requestedItemSchoolStatus]);

  useEffect(() => {
    if (isBulkSelected) {
      return;
    }

    if (requestedItemSchoolStatus !== localValue) {
      const timeoutId = setTimeout(() => {
        changeRequestedItemsStatus({
          inquiryId,
          departmentInquiryId,
          assignmentId,
          itemsIds: [requestedItemId],
          schoolStatus: localValue,
        })
          .unwrap()
          .then(handleSuccess)
          .catch((e) => {
            handleError(e);
            setLocalValue(requestedItemSchoolStatus);
          });
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [
    assignmentId,
    changeRequestedItemsStatus,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
    isBulkSelected,
    localValue,
    requestedItemId,
    requestedItemSchoolStatus,
  ]);

  const preActionCheck = usePreActionCheck();

  const { availabilityMap, hideSelect } = useMemo(() => {
    const keyValueMap: Array<[RequestedItemSchoolStatus, boolean | undefined]> =
      requestedItemSchoolStatuses.map((status) => [
        status,
        self
          ? requestedItemSchoolStatusesPaths[status]({
              item: {
                schoolStatus: requestedItemSchoolStatus,
              },
              assignment: {
                schoolStatus: assignmentSchoolStatus,
                user: {
                  id: assignmentUserId,
                },
              },
              departmentInquiry: {
                schoolStatus: departmentInquirySchoolStatus,
              },
              self,
            }) && requestedItemSchoolStatus !== status
          : false,
      ]);

    return {
      availabilityMap: Object.fromEntries(keyValueMap),
      hideSelect:
        keyValueMap.every(([, availability]) => !availability) ||
        !inquirySchoolStatus ||
        preActionCheck(inquirySchoolStatus),
    };
  }, [
    assignmentSchoolStatus,
    assignmentUserId,
    departmentInquirySchoolStatus,
    inquirySchoolStatus,
    preActionCheck,
    requestedItemSchoolStatus,
    self,
  ]);

  if (hideSelect) {
    return (
      <SchoolStatusChip
        label={t(`requestedItemSchoolStatuses.${requestedItemSchoolStatus}`)}
        chipStyle={
          REQUEST_ITEM_SCHOOL_STATUS_CHIP_STYLES[requestedItemSchoolStatus]
        }
        detached
      />
    );
  }

  return (
    <TextField
      size="small"
      fullWidth
      select
      value={localValue}
      onChange={changeHandler}
      variant="standard"
    >
      {requestedItemSchoolStatuses.map((status) => (
        <MenuItem
          key={status}
          value={status}
          disabled={
            !availabilityMap[status] ||
            isChanging ||
            departmentInquirySchoolStatus === "cancelled"
          }
        >
          <SchoolStatusChip
            label={t(`requestedItemSchoolStatuses.${status}`)}
            chipStyle={REQUEST_ITEM_SCHOOL_STATUS_CHIP_STYLES[status]}
          />
        </MenuItem>
      ))}
    </TextField>
  );
};
