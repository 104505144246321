import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCanBulkSubmit } from "./useCanBulkSubmit";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import {
  useGetSchoolAssignmentQuery,
  useGetSchoolAssignmentRequestedItemsQuery,
} from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { TableControls } from "./TableControls";
import { useListTools } from "../../../../../components/List/useListTools";
import { RequestedItemSchoolStatus } from "../../../../../types";
import { RequestedItemQuantityField } from "../RequestedItemQuantityField";
import { SelectRequestedItemStatus } from "../SelectRequestedItemStatus";
import { useSchoolInquiryItemsListColumns } from "../useSchoolInquiryItemsListColumns";
import { List } from "../../../../../components/List/List";
import { useDisplayTotalAmount } from "../useDisplayTotalAmount";
import { Actions } from "./Actions";
import { EnhancedSkeleton } from "../../../../../components/EnhancedSkeleton";

export const AssignmentItems = () => {
  const { t } = useTranslation("pages");

  const canBulkSubmit = useCanBulkSubmit();
  const preActionCheck = usePreActionCheck();
  const displayTotalAmount = useDisplayTotalAmount();

  const { selectedIds, selectedIdsChangeHandler } = useListTools();

  const { baseUrl, inquiryId, departmentInquiryId, assignmentId } =
    useSchoolInquiryItemsTools();

  const {
    data: assignment,
    isLoading: isAssignmentLoading,
    isFetching: isAssignmentFetching,
  } = useGetSchoolAssignmentQuery(assignmentId ? { assignmentId } : skipToken);

  const {
    data: requestedItems,
    isLoading: areRequestedItemsLoading,
    isFetching: areRequestedItemsFetching,
  } = useGetSchoolAssignmentRequestedItemsQuery(
    assignmentId ? { assignmentId } : skipToken
  );

  const columns = useSchoolInquiryItemsListColumns<{
    schoolStatus: RequestedItemSchoolStatus;
  }>({
    baseUrl,
    columns: useMemo(
      () => [
        {
          order: 4,
          column: {
            field: "quantity",
            name: t("schoolInquiryDetails.departmentColumns.quantity"),
            width: "150px",
            renderCell: (row) =>
              departmentInquiryId &&
              assignmentId && (
                <RequestedItemQuantityField
                  id={row.id}
                  value={row.quantity}
                  requestedItemSchoolStatus={row.schoolStatus}
                  inquiryId={inquiryId}
                  departmentInquiryId={departmentInquiryId}
                  assignmentId={assignmentId}
                  assignmentSchoolStatus={assignment?.schoolStatus}
                  assignmentUserId={assignment?.userId}
                  loading={isAssignmentFetching || areRequestedItemsFetching}
                  disabled={
                    !assignment ||
                    assignment.departmentInquirySchoolStatus === "cancelled" ||
                    preActionCheck(assignment.inquirySchoolStatus)
                  }
                />
              ),
          },
        },
        {
          order: 8,
          column: {
            field: "totalAmount",
            name: t("schoolInquiryDetails.departmentColumns.totalAmount"),
            renderCell: ({ productCode, retailPrice, currency, totalAmount }) =>
              displayTotalAmount({
                productCode,
                retailPrice,
                currency,
                totalAmount,
              }),
          },
        },
        {
          order: 9,
          column: {
            field: "status",
            name: t("schoolInquiryDetails.departmentColumns.status"),
            width: "250px",
            renderCell: (row) =>
              departmentInquiryId &&
              assignment && (
                <SelectRequestedItemStatus
                  inquiryId={inquiryId}
                  inquirySchoolStatus={assignment.inquirySchoolStatus}
                  departmentInquiryId={departmentInquiryId}
                  departmentInquirySchoolStatus={
                    assignment.departmentInquirySchoolStatus
                  }
                  assignmentId={assignment.id}
                  assignmentSchoolStatus={assignment.schoolStatus}
                  assignmentUserId={assignment.userId}
                  requestedItemId={row.id}
                  requestedItemSchoolStatus={row.schoolStatus}
                  isBulkSelected={selectedIds.includes(row.id)}
                />
              ),
          },
        },
        {
          order: 10,
          column: {
            field: "actions",
            name: "",
            width: "64px",
            boxSx: {
              justifyContent: "center",
            },
            renderSkeleton: () => (
              <EnhancedSkeleton
                variant="circular"
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "100%",
                }}
              />
            ),
            renderCell: (row) =>
              assignment && (
                <Actions
                  item={row}
                  inquiryId={inquiryId}
                  inquirySchoolStatus={assignment.inquirySchoolStatus}
                  departmentInquiryId={departmentInquiryId}
                  assignmentId={assignmentId}
                  assignmentUserId={assignment.userId}
                  assignmentSchoolStatus={assignment.schoolStatus}
                  departmentSchoolStatus={
                    assignment.departmentInquirySchoolStatus
                  }
                />
              ),
          },
        },
      ],
      [
        areRequestedItemsFetching,
        assignment,
        assignmentId,
        departmentInquiryId,
        displayTotalAmount,
        inquiryId,
        isAssignmentFetching,
        preActionCheck,
        selectedIds,
        t,
      ]
    ),
  });

  return (
    <>
      <TableControls selectedIds={selectedIds} />
      <List
        selectedIds={canBulkSubmit ? selectedIds : undefined}
        onSelectedIdsChange={selectedIdsChangeHandler}
        columns={columns}
        rows={requestedItems ?? []}
        displaySkeleton={isAssignmentLoading || areRequestedItemsLoading}
      />
    </>
  );
};
