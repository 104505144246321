import React, { useCallback } from "react";
import { CircleButton } from "../../../../components/toolbar/CircleButton";
import { InquiryDetailsFlattened } from "../../../../slices/inquiries/types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { usePermissions } from "../../../../utils/usePermissions";
import { useGenerate } from "./useGenerate";
import { useItemCounts } from "./useItemCounts";
import { useCommercialOfferDetailsCheck } from "./useCommercialOfferDetailsCheck";
import { Message } from "./Message";
import { Options } from "./Options/Options";
import { useOptions } from "./useOptions";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog";
import { Box } from "@mui/material";
import { useDownloadPdf } from "./useDownloadPdf";

type GenerateCommercialOfferProps = {
  inquiryDetails: InquiryDetailsFlattened;
  baseUrl: string;
  inquiryId: number;
  selectedIds: number[];
};

export const GenerateCommercialOffer = ({
  inquiryDetails,
  baseUrl,
  inquiryId,
  selectedIds,
}: GenerateCommercialOfferProps) => {
  const { t } = useTranslation("pages");
  const history = useHistory();

  const can = usePermissions();

  const areDetailsFilled = useCommercialOfferDetailsCheck({
    inquiryDetails,
  });

  const { newItemsCount, submittedItemsCount } = useItemCounts({
    inquiryDetails,
    selectedIds,
  });

  const {
    areOptionsLoading,
    orientation,
    orientationChangeHandler,
    includedFields,
    includedFieldsChangeHandler,
  } = useOptions({ inquiryId });

  const generate = useGenerate({
    inquiryId,
    inquiryDetails,
    selectedIds,
    orientation,
    includedFields,
  });

  const {
    pdf,
    preparePdfDownload,
    downloadPdfClickHandler,
    isPreviewVisible,
    setIsPreviewVisible,
  } = useDownloadPdf();

  const clickHandler = useCallback(() => {
    if (!areDetailsFilled) {
      history.push(`${baseUrl}/details`);
      return;
    }

    generate().then((commercialOfferId) => {
      if (typeof commercialOfferId === "number") {
        preparePdfDownload(commercialOfferId);
        setIsPreviewVisible(true);
      }
    });
  }, [
    areDetailsFilled,
    baseUrl,
    generate,
    history,
    preparePdfDownload,
    setIsPreviewVisible,
  ]);

  return (
    <>
      <CircleButton
        tooltip={t("inquiryDetails.generateCommercialOffer.tooltip")}
        confirmation={{
          title: t("inquiryDetails.generateCommercialOffer.confirmationTitle"),
          description: (
            <>
              <Message
                {...{ areDetailsFilled, newItemsCount, submittedItemsCount }}
              />
              {areDetailsFilled && (
                <Options
                  {...{
                    areOptionsLoading,
                    orientation,
                    orientationChangeHandler,
                    includedFields,
                    includedFieldsChangeHandler,
                  }}
                />
              )}
            </>
          ),
          proceedButtonText: areDetailsFilled
            ? ""
            : t("inquiryDetails.generateCommercialOffer.goToDetails"),
        }}
        onClick={clickHandler}
        disabled={!can("addCommercialOffer")}
      >
        {t("inquiryDetails.generateCommercialOffer.button")}
      </CircleButton>
      <ConfirmationDialog
        open={isPreviewVisible}
        title={t("inquiryDetails.commercialOfferPreview.title")}
        description={
          pdf ? (
            <Box
              component="iframe"
              src={pdf.url}
              sx={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
            />
          ) : null
        }
        proceedButtonText={t(
          "inquiryDetails.commercialOfferPreview.proceedButtonText"
        )}
        handleProceed={downloadPdfClickHandler}
        handleCancel={() => setIsPreviewVisible(false)}
        fullWidth
        fullHeight
        maxWidth="lg"
      />
    </>
  );
};
