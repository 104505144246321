import React from "react";
import { useTranslation } from "react-i18next";
import { DialogContentText } from "@mui/material";

export const Message = ({
  areDetailsFilled,
  newItemsCount,
  submittedItemsCount,
}: {
  areDetailsFilled: boolean;
  newItemsCount: number;
  submittedItemsCount: number;
}) => {
  const { t } = useTranslation("pages");

  if (areDetailsFilled) {
    return (
      <DialogContentText>
        {t("inquiryDetails.generateCommercialOffer.confirmationDescription", {
          count: submittedItemsCount,
        })}
        {newItemsCount !== 0 &&
          t("inquiryDetails.generateCommercialOffer.additionalDescription", {
            count: newItemsCount,
          })}
      </DialogContentText>
    );
  }

  return (
    <DialogContentText>
      {t("inquiryDetails.generateCommercialOffer.notFullCoDetails")}
    </DialogContentText>
  );
};
