import React from "react";
import { createTheme } from "@mui/material";
import { colors } from "./colors";
import {
  CheckboxChecked,
  CheckboxUnchecked,
} from "../../components/astraIcons/Checkbox";
import { Close } from "../../components/astraIcons/Close";
import { ArrowDown } from "../../components/astraIcons/ArrowDown";

export const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },

  /**
   * Required resolutions:
   * - 1280x720
   * - 1440x900
   * - 1536x960
   * - 1600x900
   * - 1680x1050
   * - 1920x1080
   */
  breakpoints: {
    values: {
      xs: 1260,
      sm: 1440,
      md: 1536, // 1492
      lg: 1600,
      xl: 1900,
    },
  },

  zIndex: {
    snackbar: 99999999999,
    drawer: 10002,
    modal: 10002,
  },

  palette: {
    primary: {
      main: colors.blue,
      dark: colors.darkBlue,
      light: colors.lightBlue,
      contrastText: colors.white,
    },

    secondary: {
      main: colors.dustBlue2,
      dark: colors.dustBlue1,
      light: colors.dustBlue4,
      contrastText: colors.darkBlue,
    },

    pink: {
      main: colors.pink,
      light: colors.pink10,
    },

    whitishGray: {
      main: colors.whitishGray,
    },

    divider: colors.dustBlue4,

    common: {
      black: colors.black,
    },
  },

  components: {
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up("xs")]: {
            paddingLeft: "32px",
            paddingRight: "32px",
          },
        }),
      },
    },

    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.dustBlue6,
          borderRadius: "4px",
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.light,
          fontSize: "13px",
        }),
      },
    },

    MuiList: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.whitishGray.main,
          "& .MuiMenuItem-root": {
            fontSize: "13px",
            color: theme.palette.secondary.dark,
          },
          "& .Mui-selected": {
            backgroundColor: "#EFF3F8", //Dust Blue 4_5
          },

          "& .MuiTypography-root": {
            fontSize: "13px",
          },
        }),
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        input: {
          height: "24px",
          lineHeight: "24px",
          padding: "0px 10px 0px 10px !important",
        },
        popper: {
          zIndex: 13000,
        },
        loading: {
          fontSize: "13px",
        },
        listbox: {
          fontSize: "13px",
          fontWeight: "400",
          color: colors.dustBlue1,
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        root: ({ theme }) => ({
          zIndex: 13000,
          ".MuiList-root": {
            borderRadius: "4px",
            background: theme.palette.common.white,
            border: `1px solid ${colors.dustBlue5}`,
            boxShadow: "0px 6px 8px 0px rgba(22, 65, 115, 0.05)",
          },

          ".MuiListItemIcon-root": {
            color: theme.palette.primary.dark,
            width: "24px",
            minWidth: "24px",
            height: "24px",
            marginRight: "8px",
          },

          ".MuiListItemText-root .MuiTypography-root": {
            fontSize: "16px",
            lineHeight: "160%",
            color: theme.palette.primary.dark,
          },
        }),
      },
      defaultProps: {
        elevation: 0,
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: 0,

          "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: theme.palette.secondary.main,
              borderWidth: "1px",
            },

          ".MuiSvgIcon-root": {
            color: theme.palette.secondary.light,
          },

          "&:hover .MuiSvgIcon-root, &.Mui-focused .MuiSvgIcon-root": {
            color: theme.palette.secondary.main,
          },
        }),
        input: ({ theme }) => ({
          color: theme.palette.secondary.contrastText,
          fontSize: "13px",
          lineHeight: "24px",
          height: "24px",
          paddingTop: "8px",
          paddingRight: "16px",
          paddingBottom: "8px",
          paddingLeft: "16px",

          "&::placeholder": {
            color: theme.palette.secondary.light,
            opacity: 1,
          },
        }),
        inputSizeSmall: {
          paddingTop: "6px",
          paddingRight: "16px",
          paddingBottom: "6px",
          paddingLeft: "16px",
        },
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.secondary.light,
          borderRadius: "4px",
        }),
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: "text" },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            textTransform: "none",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "500",
            gap: "12px",

            ".MuiSvgIcon-root": {
              color: theme.palette.primary.main,
            },

            ".MuiButton-startIcon": {
              marginRight: 0,
            },
          }),
        },
        {
          props: { variant: "contained" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderRadius: "4px",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "24px",
            textTransform: "none",
          }),
        },
        {
          props: { variant: "outlined" },
          style: ({ theme }) => ({
            borderRadius: "4px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
          }),
        },
        {
          props: { size: "medium" },
          style: {
            height: "38px",
            paddingTop: "2px",
            paddingRight: "24px",
            paddingBottom: 0,
            paddingLeft: "24px",
          },
        },
        {
          props: { disabled: true },
          style: {
            opacity: "0.5",
          },
        },
      ],
    },

    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },

    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxUnchecked sx={{ width: "20px", height: "20px" }} />,
        checkedIcon: <CheckboxChecked sx={{ width: "20px", height: "20px" }} />,
      },
      styleOverrides: {
        root: {
          padding: "8px",
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: "-8px",
        },
        label: ({ theme }) => ({
          fontSize: "14px",
          lineHeight: "24px",
          color: theme.palette.secondary.dark,
        }),
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          ".MuiPaper-root": {
            paddingTop: "41px",
            paddingRight: "40px",
            paddingBottom: "48px",
            paddingLeft: "40px",
            border: `1px solid ${colors.dustBlue5}`,
            borderRadius: "4px",
            boxShadow: "2px 0px 16px 10px rgba(51, 144, 255, 0.04)",
          },
        },
      },
    },

    MuiChip: {
      defaultProps: {
        deleteIcon: <Close />,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          gap: "4px",

          "&:active": {
            boxShadow: "none !important",
          },

          "&.MuiChip-sizeSmall": {
            paddingTop: 0,
            paddingRight: "12px !important",
            paddingBottom: 0,
            paddingLeft: "12px !important",
          },

          "&.MuiChip-sizeMedium": {
            paddingTop: "4px !important",
            paddingRight: "12px !important",
            paddingBottom: "4px !important",
            paddingLeft: "12px !important",
          },

          "&.MuiChip-outlined": {
            borderColor: theme.palette.secondary.light,
            flexDirection: "row-reverse",
            color: theme.palette.primary.dark,

            ".MuiChip-icon": {
              color: theme.palette.primary.dark,
            },
          },

          "&.MuiChip-filled": {
            backgroundColor: theme.palette.pink.light,
            color: theme.palette.pink.main,

            ".MuiChip-icon, .MuiChip-deleteIcon": {
              color: theme.palette.pink.main,
            },
          },

          ".MuiChip-label": {
            padding: 0,
            fontSize: "12px",
            lineHeight: "23px",
          },

          ".MuiChip-icon, .MuiChip-deleteIcon": {
            margin: 0,
            width: "16px",
            height: "16px",
          },
        }),
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&::before": {
            display: "none",
          },

          "&.Mui-expanded": {
            margin: 0,
          },

          ".MuiAccordionSummary-root": {
            padding: 0,
            height: "54px",
            minHeight: "54px",
            borderBottom: `1px solid transparent`,
          },

          "&.Mui-expanded .MuiAccordionSummary-root": {
            borderBottomColor: theme.palette.secondary.light,
            minHeight: "54px",
          },

          ".MuiAccordionSummary-content": {
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "24px",
            color: theme.palette.primary.dark,
            margin: 0,
          },

          ".MuiAccordionSummary-expandIconWrapper": {
            paddingRight: "8px",
            paddingLeft: "8px",
            color: theme.palette.primary.dark,
          },

          ".MuiAccordionSummary-expandIconWrapper svg": {
            fontSize: "24px",
          },

          ".MuiAccordionDetails-root": {
            padding: 0,
          },
        }),
      },
    },

    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ArrowDown />,
      },
    },

    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          sx: {
            zIndex: 25000,
          },
        },
      },
    },
  },
});
