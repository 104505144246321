export const colors = {
  white: "#FFFFFF",

  whitishGray: "#F7F9FB",

  blue: "#3390FF",
  lightBlue: "#E7F1FF",
  darkBlue: "#164173",

  dustBlue1: "#5E7DA0",
  dustBlue2: "#8AA0B9",
  dustBlue4: "#C5D2E0",
  dustBlue5: "#E0E8F1",
  dustBlue6: "#EFF3F8",

  pink: "#C03CC7",
  pink10: "#F9EBF9",

  black: "#141414",
};
