import { ItemSchoolStatus } from "../../../../../slices/items/types";
import { useSelector } from "../../../../../store";
import { useMemo } from "react";
import { selectSelf } from "../../../../../slices/auth/selectors";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import { useGetSchoolInquiryQuery } from "../../../../../slices/schoolInquiries/api";

export const useCanBulkSubmit = (): ItemSchoolStatus | false => {
  const self = useSelector(selectSelf);

  const { inquiryId, departmentInquiryId } = useSchoolInquiryItemsTools();

  const { data: inquiry } = useGetSchoolInquiryQuery(inquiryId);

  const departmentInquiry = useMemo(
    () =>
      inquiry?.departmentInquiries.find(
        (departmentInquiry) => departmentInquiry.id === departmentInquiryId
      ),
    [departmentInquiryId, inquiry?.departmentInquiries]
  );

  const preActionCheck = usePreActionCheck();

  return useMemo(() => {
    if (!departmentInquiry || !self || !inquiry) {
      return false;
    }

    if (preActionCheck(inquiry.schoolStatus)) {
      return false;
    }

    if (self.role === "headOfDepartment") {
      return departmentInquiry.assignments.every(
        (assignment) =>
          assignment.schoolStatus === "approvedByHead" ||
          assignment.schoolStatus === "cancelled"
      )
        ? "approvedByHead"
        : false;
    }

    if (self.role === "principal") {
      return departmentInquiry.schoolStatus === "approvedByHead"
        ? "approvedByPrincipal"
        : false;
    }

    return false;
  }, [departmentInquiry, inquiry, preActionCheck, self]);
};
